<template>
  <div>
    <b-overlay center :show="isLoading" rounded="sm">
      <section
        class="cont bg-image"
        :style="{
          backgroundImage: `url(${require('@/assets/lisaweb.png/')})`,
        }"
      >
        <img
          :src="require('@/assets/lisaweb.png')"
          alt="Lisaweb 3"
          class="left-logo"
        />
        <div class="content">
          <div class="login" v-if="!isLoading">
            <h3>Credenziali di accesso utente</h3>
            <validation-observer
              ref="observer"
              v-slot="{ invalid, handleSubmit }"
            >
              <b-form @submit.prevent="handleSubmit(onSubmit)">
                <div class="mt-5">
                  <base-input
                    name="Utente"
                    :label="beForm[rep].username.label"
                    v-model="form.username"
                    :rules="getRules('username', 'auth')"
                    placeholder="Inserisci il nome utente"
                  />
                  <base-input
                    name="Password"
                    :label="beForm[rep].password.label"
                    type="password"
                    v-model="form.password"
                    :rules="getRules('password', 'auth')"
                    placeholder="Inserisci la password"
                  />
                  <!-- <div style="font-size: 0.75rem">
                    <b-link
                      href="forgot-password"
                      @click.prevent="forgotPassword()"
                    >
                      <span>Password dimenticata?</span>
                    </b-link>
                  </div> -->
                  <div style="display: flex; justify-content: flex-end">
                    <b-button
                      type="submit"
                      variant="lisaweb"
                      size="sm"
                      :disabled="invalid"
                      >{{ beForm["auth"].submit.label }}</b-button
                    >
                  </div>
                </div>
              </b-form>
            </validation-observer>
            <Info></Info>
          </div>
        </div>
      </section>
      <Footer :version="version"></Footer>
      <template #overlay>
        <div class="text-center">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">{{ msg || "Operazione in corso..." }}</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import Info from "@/components/Info";
import Footer from "@/components/Footer";
import FormMixin from "@/mixins/FormMixin";
import { mapGetters } from "vuex";
import { canModule, canVerb } from "@/utils/permissions";

export default {
  mixins: [FormMixin],
  data() {
    return {
      isLoading: true,
      bgImage: { "background-image": 'url("../assets/lisaweb.png")' },
      form: {
        username: "",
        password: "",
      },
      show: true,
      messages: [
        "Caricamento dati in corso...",
        "Questa operazione potrebbe impiegare alcuni secondi...",
        "Attendere prego...",
      ],
      delay: 6000,
    };
  },
  components: {
    BaseIcon,
    BaseInput,
    Info,
    Footer,
  },
  mounted() {
    this.isLoading = true;
    this.fetchCreateForm("auth").then(() => {
      this.isLoading = false;
    });
  },

  methods: {
    canModule,
    canVerb,
    onSubmit() {
      this.loadMsg();
      this.isLoading = true;
      this.$store.commit("sidebar/SIDEBAR_LOCKED_SAVE", true);

      this.$store
        .dispatch("auth/login", {
          // email: this.form.email,
          username: this.form.username,
          password: this.form.password,
        })
        .then(() => {
          // if at least 1 error, redirect to Login
          // let agency_brokers = this.$store.dispatch("auth/agency_brokers");
          // let book_tags = this.$store.dispatch("auth/book_tags");
          let branches = this.$store.dispatch("auth/branches");
          // let cooperators = this.$store.dispatch("auth/cooperators");
          // let councils = this.$store.dispatch("auth/councils");
          // let countries = this.$store.dispatch("auth/countries");
          // let insurers = this.$store.dispatch("auth/insurers");
          // let mandate_codes = this.$store.dispatch("auth/mandate_codes");
          // let payment_refrences = this.$store.dispatch("auth/payment_references");
          let products = this.$store.dispatch("auth/products");
          // let provinces = this.$store.dispatch("auth/provinces");
          // let relative_types = this.$store.dispatch("auth/relative_types");
          let cash_references = this.$store.dispatch("auth/cash_references");
          // let lisa_for_apps = this.$store.dispatch("auth/lisa_for_apps");
          // let reports = this.$store.dispatch("auth/reports");
          let risks = this.$store.dispatch("auth/risks");
          let salesmen = this.$store.dispatch("auth/salesmen");
          let sectors = this.$store.dispatch("auth/sectors");
          let treasuries = this.$store.dispatch("auth/treasuries");
          let conti_prima_nota = this.$store.dispatch("auth/conti_prima_nota");
          // let users = this.$store.dispatch("auth/users");
          let count_import_maps = this.$store.dispatch("auth/counters", {
            resource: "import_maps",
            query: "byTreasury[id]=!&count",
          });

          let promises = [
            // agency_brokers,
            // book_tags,
            branches,
            cash_references,
            // cooperators,
            // councils,
            count_import_maps,
            // countries,
            // insurers,
            // lisa_for_apps,
            // mandate_codes,
            // payment_refrences,
            products,
            // provinces,
            // relative_types,
            // reports,
            risks,
            salesmen,
            sectors,
            treasuries,
            conti_prima_nota,
            // users,
          ];

          // conditional dispatch:
          if (
            canModule("module.HOME.LAPP") &&
            canVerb("lisa_for_apps", "index")
          ) {
            let lisa_for_apps = this.$store.dispatch("auth/lisa_for_apps");
            promises.push(lisa_for_apps);
          }

          Promise.all(promises)
            .then(() => {
              console.info("all background data fetched");
              // han deciso che devo caricare la dashborad solo dopo che l'ultima promise ha finito
              this.$router.replace({ name: "module.HOME" });
              this.$store.commit("sidebar/SIDEBAR_LOCKED_SAVE", false);
              document.body.className = "logged";
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({ preset: "error", text: errMsg });
            })
            .finally(() => {
              this.unloadMsg();
              this.isLoading = false;
            });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false; // when wrong password, must stop loading...
        });
      // .finally(() => {
      //   this.unloadMsg();
      //   this.isLoading = false; // when wrong password, must stop loading...
      // });
    },
    forgotPassword() {
      // this.$showSnackbar({
      //   preset: "info",
      //   text: "Si è scelto di resettare la password",
      // });
      this.$router.replace({ name: "ForgotPassword" });
      // this.$router.replace({ name: "ResetPassword" });
    },
  },
  computed: {
    ...mapGetters("login_data", ["isLoginData"]),
    // ver() {
    //   return this.version;
    // },
    // bu() {
    //   return `http://${window.location.hostname.split(".")[0]}.${
    //     process.env.VUE_APP_BASE_URL
    //   }`;
    // },
    // cliVer() {
    //   return process.env.VUE_APP_VERSION || "0";
    // },
    rep() {
      return "auth";
    },
    formFilled() {
      return !(this.form.username || this.form.password);
    },
  },
};
</script>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.login {
  width: 100%;
  height: 100%;
  max-width: 450px;
  padding: 100px 20px;
  margin: auto;
  font-size: 16px;
  line-height: 25px;
}

h3 {
  font-weight: bold;
}

h5 {
  font-weight: bold;
}

// .footer {
//   margin-top: 20%;
// }
.cont {
  display: flex;
  height: 100vh;
  margin-left: 0 !important;
  padding-left: 0 !important;
}

.bg-image {
  /* The image used */
  // background-image: url("../assets/lisaweb.png");

  /* Center and scale the image nicely */
  background-repeat: no-repeat;
  // background-position: left top;
  // background-size: 100% 100%;
  // flex: 1;
  // height: 100%;

  /* Override */
  // background-size: contain;
}

.left-logo {
  width: 500px;
  max-width: 50%;
  min-width: 25%;
  opacity: 0;
}

@media screen and (max-width: 1280px) {
  .bg-image {
    background-image: none !important;
  }
  .left-logo {
    clip-path: polygon(0 30px, 100% 30px, 100% 170px, 0% 170px);
    opacity: 1;
    max-width: 100%;
    min-width: 0;
    align-self: start;
    margin: -30px auto 0;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .login {
    padding-top: 180px;
  }
}

@media screen and (max-width: 641px) {
  .left-logo {
    clip-path: polygon(0 30px, 100% 30px, 100% 130px, 0% 130px);
  }
  .login {
    padding-top: 150px;
  }
}

@media screen and (min-height: 1081px) {
  .bg-image {
    background-size: contain;
  }
}

.content {
  flex: 2;
  height: 100%;
  overflow: auto;
}

// /* Fluid container API */
// p {
//   font-size: 1rem;
//   line-height: 1.5rem;
// }
// .fluid-container.api {
//   background: #6f6259;
//   position: absolute;
//   bottom: 0;
//   width: 100%;
// }
// .fluid-container.api > *:last-child {
//   margin-bottom: 0px;
//   color: #fff;
//   font-size: 1rem;
// }
</style>
